
@use '../../styles/styles.scss' as lib;

@include lib.col;
@include lib.mx-2;
@include lib.px-2;
@include lib.pt-3;
@include lib.mt-4;
@include lib.py-4;
@include lib.row;

.login-tile {
  width: 450px;
}

.form-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: lib.$gm55;
}

aab-input,
aab-inputblock-password {
  width: 100%;
}

