
@use '../../styles/styles.scss' as lib;

@include lib.mt_3;
@include lib.px_3;

.controls {
  display: flex;
  justify-content: space-between;
}

.footer {
  display: flex;
  justify-content: space-between;
}

