
@use '../../styles/styles.scss' as lib;

@include lib.col;
@include lib.mx-2;
@include lib.px-2;
@include lib.pt-3;
@include lib.mt-4;
@include lib.py-4;
@include lib.row;

.forget-password-tile {
  width: 450px;
}

.form-footer {
  display: flex;
  justify-content: flex-end;
  background-color: lib.$gm55;
}

.notification {
  max-width: inherit;
}

aab-input {
  width: 100%;
}

