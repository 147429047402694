
@use '../../styles/styles.scss' as lib;

@include lib.mt-3;
@include lib.row;
@include lib.col;
@include lib.container_fluid;

.controls {
  display: flex;
  justify-content: flex-start;
}

.spaceBetween {
  display: flex;
  justify-content: space-between;
}

