
@use '../../styles/styles.scss' as lib;

@include lib.mb-2;
@include lib.mr-2;
@include lib.mt-2;
@include lib.mt-3;

.form {
  width: 500px;
}

.controls {
  display: flex;
  justify-content: flex-start;
}

